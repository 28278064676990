@import './css/general.css';

/****** LOGO ******/

.logo {
  max-width: 300px;
}

/****** HOMEPAGE BUTTON ******/

.diagonal-button,
.diagonal-button *,
.diagonal-button :after,
.diagonal-button :before,
.diagonal-button:after,
.diagonal-button:before {
  border: 0 solid;
  box-sizing: border-box;
}

.diagonal-button {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: var(--gcs-purple);
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(var(--gcs-hot-pink), #fff);
  padding: 0;
  text-transform: uppercase;
}

.diagonal-button:disabled {
  cursor: default;
}

.diagonal-button:-moz-focusring {
  outline: auto;
}

.diagonal-button svg {
  display: block;
  vertical-align: middle;
}

.diagonal-button [hidden] {
  display: none;
}

.diagonal-button {
  border-radius: 99rem;
  overflow: hidden;
  padding: 0.8rem 3rem;
  position: relative;
}

.diagonal-button span {
 mix-blend-mode: plus-lighter;
}

.diagonal-button:before {
  --tilt: 1.2rem;
  background: var(--gcs-hot-pink);
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - var(--tilt)) 0,
    100% 100%,
    0 100%
  );
  clip-path: polygon(0 0, calc(100% - var(--tilt)) 0, 100% 100%, 0 100%);
  content: "";
  display: block;
  height: 100%;
  left: calc(-100% - var(--tilt));
  position: absolute;
  top: 0;
  transform: translateX(var(--progress, 0));
  transition: transform 0.2s ease;
  width: calc(100% + var(--tilt));
}

.diagonal-button:hover:before {
  --progress: 100%;
}

/***************************************************** MEDIA QUERIES: MAX WIDTH 400px ***************************************************/

@media (max-width: 399.98px) {

  .logo {
    max-width: 250px;
  }

}