
/*************** CSS CUSTOM COLORS & PROPERTIES ***********/

:root {

/******************* GCS Colors ******************/

--gcs-white: #FFFFFF;
--gcs-off-white: #F5F5F5;
--gcs-hot-pink: #B650B2;
--gcs-sweet-pink: #F0CBEF;
--gcs-muted-pink: #E9BBD7;
--gcs-purple: #5E46BF;
--gcs-dark-purple: #800080;
--gcs-lavender: #BBAFEC;
--gcs-turquoise: #39AFB9;
--gcs-blue-link: #4169E1;
--gcs-dark-blue: #344767;
--gcs-medium-blue: #6A85B4;
--gcs-green: #138808;
--gcs-light-blue: #B9E9F0;
--gcs-bright-blue: #72B4DF;
--gcs-gold: #F4BF4C;
--gcs-cc-blue: #06689C;

/***************** Font Family ***************/

--ff-primary: 'Nunito Sans', sans-serif;
--ff-heading: 'Oswald', sans-serif;

/*********** Font Size ; 1rem = 16px **********/

/** Typical h1 - h6 font sizes **/

--fs-h1: 2rem;
--fs-h2: 1.5rem;
--fs-h3: 1.17rem;
--fs-h4: 1.05rem;
--fs-h5: 0.83rem;
--fs-h6: 0.67rem;

--text-h1: var(--fs-h1);
--text-h2: var(--fs-h2);
--text-h3: var(--fs-h3);
--text-h4: var(--fs-h4);
--text-h5: var(--fs-h5);
--text-h6: var(--fs-h6);

/** Font sizes for section titles (primary heading) & 
 * subtitles (secondary heading) **/

--fs-600: 2.5rem;
--fs-555: 2.0rem;
--fs-500: 1.5rem;
--fs-400: 1.1rem;

--text-primary-heading: var(--fs-600);
--text-secondary-heading: var(--fs-500);
--text-tertiary-heading: var(--fs-400);

--text-title-heading: var(--fs-555);

/** Font size for body text (h4 = 16px) **/

--text-body: var(--fs-h4);

/** Font size for nav menu (h2 = 24px) **/

--text-nav: var(--fs-h2);

/** Font size for buttons (h5 = 13.28px) **/

--text-button: var(--fs-h5);

}

/**************** HTML & BODY PROPERTIES *****************/

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html {
  height: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  line-height: 1.5;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(240,203,239);
  background-color: linear-gradient(90deg, rgba(240,203,239,1) 54%, rgba(255,255,255,1) 54%);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container {
  width: 100%;
}

/* Below is for debugging overflow / horizontal scroll */

/* {
    outline: solid 3px red; 
}

.outline {
  outline: solid 3px red;
}*/

/*************************** Buttons **************************/

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--gcs-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
}

.btn-dark-blue {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-dark-blue);
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
}

.btn-dark-blue:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-dark-blue);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-dark-blue);
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
}

.contact-hero-btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-maroon);
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.contact-hero-btn:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-maroon);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-maroon);
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.error-404-btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-maroon);
  border: 1px solid transparent;
  padding: 0.75em 1.5em;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

.error-404-btn:hover {
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .1rem;
  color: var(--ydb-maroon);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--ydb-off-white);
  border: 1px solid var(--ydb-maroon);
  padding: 0.75em 1.5em;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  box-shadow: 0px 8px 15px #CCCCCC;
}

/**************************** Hyperlinks ********************************/

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

/******************************** Images ********************************/

img {
  max-width: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/****************************** Badge & Badge Colors *****************************/

.badge {
  display: inline-block;
  padding: 0.55em 0.9em;
  font-size: 0.95em;
  line-height: 1.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.45rem;
}

.badge-hot-pink {
  background-color: var(--gcs-hot-pink);
  color: var(--gcs-white);
  font-weight: 550;
}

/**************************** Border Radius ******************************/

.rounded {
  border-radius: 0.25rem !important;
}

.drop-shadow {
  filter: drop-shadow(0.4rem 0.4rem 0.45rem rgba(0, 0, 30, 0.5));
}

/*************************** Background Colors **************************/

.bg-white {
  background-color: var(--gcs-white);
}

.bg-off-white {
  background-color: var(--gcs-off-white);
}

.bg-hot-pink {
  background-color: var(--gcs-hot-pink);
}

.bg-sweet-pink {
  background-color: var(--gcs-sweet-pink);
}

.bg-muted-pink {
  background-color: var(--gcs-muted-pink);
}

.bg-purple {
  background-color: var(--gcs-purple);
}

.bg-dark-purple {
  background-color: var(--gcs-dark-purple);
}

.bg-lavender {
  background-color: var(--gcs-lavender);
}

.bg-medium-blue {
  background-color: var(--gcs-medium-blue);
}

.bg-turquoise {
  background-color: var(--gcs-turquoise);
}

.bg-bright-blue {
  background-color: var(--gcs-bright-blue);
}

/****************************** Font Family *****************************/

.serif-heading {
  font-family: var(--ff-heading);
}

/****************************** Text Colors *****************************/

.text-white {
  color: var(--gcs-white) !important;
  font-weight: 500;
}

.text-off-white {
  color: var(--gcs-off-white) !important;
}

.text-hot-pink {
  color: var(--gcs-hot-pink) !important;
  font-weight: 500;
}

.text-sweet-pink {
  color: var(--gcs-sweet-pink) !important;
  font-weight: 500;
}

.text-muted-pink {
  color: var(--gcs-muted-pink);
  font-weight: 500;
}

.text-purple {
  color: var(--gcs-purple);
  font-weight: 500;
}

.text-dark-purple {
  color: var(--gcs-dark-purple);
  font-weight: 500;
}

.text-dark-grey {
  color: #484848 !important;
  font-weight: 500;
}

.text-dark-blue {
  color: var(--gcs-dark-blue);
  font-weight: 500;
}

.text-medium-blue {
  color: var(--gcs-medium-blue);
  font-weight: 500;
}

.text-turquoise {
  color: var(--gcs-turquoise);
  font-weight: 500;
}

.text-blue-link {
  color: var(--gcs-blue-link);
  font-weight: 500;
}

.text-green {
  color: var(--gcs-green);
  font-weight: 500;
}

.text-light-blue {
  color: var(--gcs-light-blue);
  font-weight: 500;
}

.text-cc-blue {
  color: var(--gcs-creative-coast-blue);
  font-weight: 500;
}

.text-bright-blue {
  color: var(--gcs-bright-blue);
  font-weight: 500;
}

.text-gold {
  color: var(--gcs-gold);
  font-weight: 500;
}

.dark-blue-bold-text {
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #344767;
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -0.03rem;
}

.text-grey {
  color: #7f8fa6;
  font-weight: 200;
  line-height: 1.625;
  letter-spacing: 0.90px;
  font-size: 20px;
}

.text-cc-blue {
    color: var(--gcs-cc-blue);
  font-weight: 500;
}

/****************************** Text Size *****************************/

.text-size-primary-heading {
  font-size: var(--text-primary-heading);
  line-height: 1.25em;
}

.text-size-secondary-heading {
  font-size: var(--text-secondary-heading);
  line-height: 1.10em;
}

.text-size-tertiary-heading {
  font-size: var(--text-tertiary-heading);
  line-height: 1.10em;
}

.text-size-title-heading {
  font-size: var(--text-title-heading);
  line-height: 1.10em;
}

.text-size-body {
  font-size: var(--text-body);
}

.text-size-nav {
  font-size: var(--text-nav);
}

.text-size-button {
  font-size: var(--text-button);
}

.text-size-h1 {
  font-size: var(--text-h1);
}

.text-size-h2 {
  font-size: var(--text-h2);
}

.text-size-h3 {
  font-size: var(--text-h3);
}

.text-size-h4 {
  font-size: var(--text-h4);
}

.text-size-h5 {
  font-size: var(--text-h5);
}

.text-size-h6 {
  font-size: var(--text-h6);
}

/****************************** Alignment *****************************/

.text-center {
  text-align: center;
}

/********** HR (Horizontal Rule) & VR (Vertiacal Rule) Elements **********/

.vertical-line {
    height: 20px;
    border-right: 1px solid #000900;
}

/***************************** Opacity ******************************/

.opacity-2 {
  opacity: 0.2 !important;
}

/***************************** Drop Shadow ******************************/

.drop-shadow {
  filter: drop-shadow(0.4rem 0.4rem 0.45rem rgba(0, 0, 30, 0.5));
}

/***************************** Rounded Circle ******************************/

.rounded-circle {
    border-radius: 50%!important;
}

.icon-shape {
  width: 48px;
  height: 48px;
  background-position: center;
  border-radius: 0.625rem;
}

.border-radius-lg {
  border-radius: 0.625rem;
}

.border-blue {
  border: 1px solid var(--gcs-purple);
}

.border-hot-pink {
  border: 1px solid var(--gcs-hot-pink) !important;
}

.border-purple {
  border: 1px solid var(--gcs-purple) !important;
}

.border-purple-3px {
  border: 3px solid var(--gcs-purple) !important;
}

.border-grey {
  border: 1px solid #333333 !important;
}

.border-light-grey {
  border: 1px solid #D3D3D3 !important;
}

.border-lavender-3px {
  border: 3px solid #BBAFEC !important;
}

.border-white-3px {
  border: 3px solid #FFFFFF !important;
}

.border-white-15px {
  border: 15px solid #FFFFFF !important;
}

.border-hot-pink-10px {
  border: 10px solid var(--gcs-hot-pink) !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.mb-md-0 {
  margin-bottom: 0 !important;
}

/******************** TEXT PROPERTIES *******************/

.hidden {
  display: none;
}

.bold {
  font-weight: 600;
}

.semi-bold {
  font-weight: 575;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

/****************************** Badge & Badge Colors *****************************/

.badge {
  display: inline-block;
  padding: 0.55em 0.9em;
  font-size: 0.95em;
  line-height: 1.3;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.45rem;
}

.badge-dark-purple {
  background-color: var(--gcs-dark-purple);
}

/************************** LOADING SPINNER ******************/

#loading-spinner {
  display: none;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  background-color: #ffffff;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 3000;
}

#loading-spinner:before {
  content:'';
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border-top: 10px solid var(--gcs-hot-pink);
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  z-index: 3000;
  top: -10px;
  left: -10px;
  animation: rt 1s linear infinite;
}

#loading-spinner img {
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

@keyframes rt {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes rt {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/**************** FUNCTION SPINNER ****************/

#function-spinner {
  display: none;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  background-color: #ffffff;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 3000;
}

#function-spinner:before {
  content:'';
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border-top: 10px solid var(--gcs-hot-pink);
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  z-index: 3000;
  top: -10px;
  left: -10px;
  animation: rt 1s linear infinite;
}

#function-spinner img {
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

@keyframes rt {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes rt {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/***************************** MEDIA QUERIES ***********************/

@media (min-width: 50em) {

  :root {
    --fs-nav: var(--fs-500);
    --fs-primary-heading: var(--fs-600);
    --fs-secondary-heading: var(--fs-700);
    --fs-body: var(--fs-400);
  }

}



